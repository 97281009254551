import React from 'react';

const WordMovingAnimation = (props) => {

    return (
        <div className={`wordContainer ${props?.className}`} >
            <div className={`wordInner ${props?.direction}`}>
                {props?.dataArray?.map((text, index) => {
                    return (
                        <div key={`${text}_${index}`} className='eachWord' >
                            {text}
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default WordMovingAnimation;