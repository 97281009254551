import { Route, Routes, MemoryRouter } from 'react-router-dom';
import Home from "./Home";
// import Project from '../Version1/Project';
import Navbar from './Navbar';
import HomeFooter from './HomeFooter';
import ResumePdf from './ResumePdf';
import AbsoluteImages from './AbsoluteImages';
import { useCallback, useState } from 'react';
import About from './About';
import Project from './Project';


const MainV3 = () => {
  const [currentPage, setCurrentPage] = useState("/");

  const handleCurrentPage = useCallback((newPage) => setCurrentPage(newPage), []);


  return (
    <div className="mainV3">
        <MemoryRouter 
          initialEntries={["/"]}
          initialIndex={1}
        >
          <Navbar currentPage={currentPage} setCurrentPage={handleCurrentPage} />
          <AbsoluteImages currentPage={currentPage} />
          <Routes>
              <Route exact path="/" element={<Home  />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/resume" element={<ResumePdf />} />
              <Route exact path="/project/:id" element={<Project />} />
          </Routes>
          { currentPage !== "resume" && <HomeFooter />}
        </MemoryRouter>
    </div>
  );
}

export default MainV3;
