import { Version } from './constants';
import { PortfolioData } from './context';
import MainV1 from './Version1/MainV1';
import MainV2 from './Version2/MainV2';
import MainV3 from './Version3/MainV3';


const Main = () => {
  const { version } = PortfolioData();

  if (version === Version.VERSION1) {
    return (
        <MainV1 />
    )
  }  

  if (version === Version.VERSION2) {
    return <MainV2 />
  }
  
  if (version === Version.VERSION3) {
    return <MainV3 />
  }
}

export default Main;
