import React, { useRef } from 'react';
import HomeOne from './HomeOne';
import HomeTwo from './HomeTwo';
import HomeThree from './HomeThree';
import HomeFour from './HomeFour';

const HomeHeader = () => {
    const elementRef = useRef(null);

    return (
        <div className='homeHeaderContainer'  ref={elementRef} >
            <div className='horizontalLineOne' />
            <div className='verticalLineTwo' />
            <div className='verticalLineThree' />
            <div className='horizontalLineFour' />
            <div className='horizontalLineFive' />
            <div className='verticalLineSix' />
            <div className='horizontalLineSeven' />
            <HomeFour />
            <div className='homeHeaderContent'>
                <HomeOne />
                <HomeTwo />
                <HomeThree />
            </div>
        </div>
    )
};

export default HomeHeader;