import React from 'react';
import scrollImage from '../../Assets/scroll.png';

const HomeThree = () => {
    return (
        <div className='flex justify-center mt-20' >
            <div className='scrollSection' >
                <div className='scrollImage'>
                    <img src={scrollImage} alt="Scroll" />
                </div>
                <div className='scrollText' >
                    Scroll down here
                </div>
            </div>
        </div>
    )
};

export default HomeThree;