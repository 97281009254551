import React from 'react';
import circleAnimation from '../../Assets/circleAnimation.png';

const HomeFour = () => {
  return (
    <div className='h3_cA' >
        <img src={circleAnimation} alt="cirlce animation" />
    </div>
  );
};

export default HomeFour;