import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = ({ currentPage, setCurrentPage }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleScroll = (e) => {
            const scrollHeight = e.currentTarget.scrollY;
            if (scrollHeight > 560 && currentPage === "/") {
                setCurrentPage("work");
            } 
            if (scrollHeight < 560 && currentPage === "work") {
                setCurrentPage("/");
            }
            if (scrollHeight > 2600 && currentPage === "work") {
                setCurrentPage("contact");
            }
            if (scrollHeight < 2600 && currentPage === "contact") {
                setCurrentPage("work");
            }
        }

        if (location && location?.pathname && location?.pathname.includes("/project/")) {
            setCurrentPage("work");
        } else {
            if (currentPage === "/" || currentPage === "work" || currentPage === "contact") {
                window.addEventListener('scroll', handleScroll);
            }
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
      },[currentPage, location, setCurrentPage])
    
      

    const handlePageChange = (page) => {
        if (page === "work") {
            if (currentPage === "/") {
                window.scrollTo({
                    top: 700,
                    behavior: 'smooth',
                  });
            } else {
                navigate("/");
                setCurrentPage("/");
                setTimeout(() => {
                    window.scrollTo({
                        top: 700,
                        behavior: 'smooth',
                      });
                }, 100)
            }
        } else if (page === "contact") {
            if (currentPage !== "resume") {
                window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: "smooth",
                });
            } else {
                navigate("/");
                setCurrentPage("/");
                setTimeout(() => {
                    window.scrollTo({
                        top: document.body.scrollHeight,
                        behavior: 'smooth',
                      });
                }, 100)
            }
        } else {
            navigate(page);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
        setCurrentPage(page);
    }

  return (
    <div className='homeV3Navbar_main' >
        <div className='homeV3HeaderNav' >
            <div className={`homeV3HeaderNavItem ${currentPage === "/" ? "focused" : ""}`} onClick={() => handlePageChange("/")}>Home</div>
            <div className={`homeV3HeaderNavItem ${currentPage === "work" ? "focused" : ""}`} onClick={() => handlePageChange("work")}>Work</div>
            <div className={`homeV3HeaderNavItem ${currentPage === "about" ? "focused" : ""}`} onClick={() => handlePageChange("about")}>About</div>
            <div className={`homeV3HeaderNavItem ${currentPage === "contact" ? "focused" : ""}`} onClick={() => handlePageChange("contact")}>Contact</div>
            <div className={`homeV3HeaderNavItem resumeButton ${currentPage === "resume" ? "focused" : ""}`} onClick={() => handlePageChange("resume")}></div>
        </div>
    </div>
  );
};

export default Navbar;
