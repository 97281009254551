import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState("/");
    const location = useLocation();

    useEffect(() => {
        const handleScroll = (e) => {
            const scrollHeight = e.currentTarget.scrollY;
            if (scrollHeight > 560 && currentPage === "/") {
                setCurrentPage("work");
            } 
            if (scrollHeight < 560 && currentPage === "work") {
                setCurrentPage("/");
            }
        }

        if (location && location?.pathname && location?.pathname.includes("/project/")) {
            setCurrentPage("work");
        } else {
            if (currentPage === "/" || currentPage === "work") {
                window.addEventListener('scroll', handleScroll);
            }
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
      },[currentPage, location, setCurrentPage])
    
      

    const handlePageChange = (page) => {
        if (page === "work") {
            if (currentPage === "/") {
                window.scrollTo({
                    top: 700,
                    behavior: 'smooth',
                  });
            }
            else {
                navigate("/");
                setCurrentPage("/");
                setTimeout(() => {
                    window.scrollTo({
                        top: 700,
                        behavior: 'smooth',
                      });
                }, 100)
                
            }
        }
        else {
            navigate(page);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
        }
        setCurrentPage(page);
    }

  return (
    <div className='homeV2Navbar_main' >
        <div className='homeV2HeaderNav' >
            <div className={`homeV2HeaderNavItem ${currentPage === "/" ? "focused" : ""}`} onClick={() => handlePageChange("/")}>Home</div>
            <div className={`homeV2HeaderNavItem ${currentPage === "work" ? "focused" : ""}`} onClick={() => handlePageChange("work")}>Work</div>
            <div className={`homeV2HeaderNavItem ${currentPage === "about" ? "focused" : ""}`} onClick={() => handlePageChange("about")}>About</div>
            <div className={`homeV2HeaderNavItem ${currentPage === "resume" ? "focused" : ""}`} onClick={() => handlePageChange("resume")}>Resume</div>
        </div>
    </div>
  );
};

export default Navbar;
