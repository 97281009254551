export const CONTENT_TYPE = {
    TARGET_AUDIENCE: "TARGETAUDIENCE",
    COMPETITIVE_ANALYSIS: "COMPETITIVEANALYSIS",
    PARA_POINTS: "PARAPOINTS",
    BANNER_POINTS: "BANNERPOINTS",
    IMAGE: "IMAGE",
    TITLE_VALUE: "TITLEVALUE",
    BANNER: "BANNER",
    SECTION: "SECTION",
    STEP_BANNER: "STEPBANNER",
    TITLE_VALUES: "TITLEVALUES",
    HEADING: "HEADING",
    SUBHEADING: "SUBHEADING",
    COLORS: "COLORS",
    KEY_VALUE: "KEYVALUE",
    CAROUSEL: "CAROUSEL",
  };

export const POSITION_TYPE = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  CENTER: "CENTER"
}

export const Version = {
  VERSION1: "VERSION_1",
  VERSION2: "VERSION_2",
  VERSION3: "VERSION_3"
}