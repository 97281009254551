import React, { useRef } from 'react';
import sendImage from '../../Assets/send.png';
import WordMovingAnimation from './WordMovingAnimation';
import { SectionWord, WorkWord } from './ProjectData';
import LazyImage from './LazyImage';
import { useNavigate } from 'react-router-dom';

const HomeProjects = (props) => {
    const navigate = useNavigate();
    const elementRef = useRef(null);

    const handleProjectPortfolio = (projectId) => {
        navigate("/project/"+ projectId)
    }

    return (
        <div 
            ref={elementRef}
            className='homeProjectContainer'
        >
            <WordMovingAnimation dataArray={WorkWord} direction='right' className="wmat" />
            <WordMovingAnimation dataArray={SectionWord} direction='left'  className="wmab" />
            <div className='homeProjectContent' >
                {props?.data?.map((project) => {
                    return (
                        <div id={project?.id} className='h3_eachProject' >
                            <div className='ep_includes' >
                                {project?.tags?.map((tag, index) => {
                                    return (
                                        <div id={tag} className='mr-4 flex items-center' >
                                            <div className='ep_include font-satoshi'>
                                                {tag}
                                            </div>
                                            {index !== project?.tags?.length - 1 && <div className='include_dot' />}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='flex justify-between'>
                                <div className='h3_eP_left' >
                                    <div className='h3_ep_title font-satoshi' >
                                        {project?.title}
                                    </div>
                                    <div className='h3_ep_subText' >
                                        {project?.infoText}
                                    </div>
                                    <div className='h3_ep_button font-satoshi' onClick={() =>  handleProjectPortfolio(project?.projectPortfolioId)} >
                                        View Case Study
                                        <img src={sendImage} alt="send" />
                                    </div>
                                </div>
                                <div className='h3_eP_right'>
                                    {project?.imageUrl && <LazyImage key={project?.id} src={project?.imageUrl} alt={`Image ${project?.id}`} />}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default HomeProjects;