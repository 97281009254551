import React from 'react';
import ImageOne from '../../Assets/Image_Header.png';
import ImageTwo from '../../Assets/Main_Page.png';
import { useLocation } from 'react-router-dom';

const AbsoluteImages = ({ currentPage }) => {
    const location = useLocation();

    const getLocation = () => {
        if (location && location?.pathname && location?.pathname.includes("/project/")) {
            return "project"
        } else return "";
    }

    return (
        <div>
            <div className={`absoluteImage one ${currentPage}`} >
                <img src={ImageOne} alt="background" />
            </div>
            {currentPage !== "resume" && <div className={`absoluteImage two ${currentPage} ${getLocation()}`} >
                <img src={ImageTwo} alt="background two" />
            </div>}
        </div>
    )
};

export default AbsoluteImages;