import React from 'react';


const Loader = () => {

    return (
        <div className='v3loaderMain' >
            <div className="v3loader"></div>
        </div>
    )
};

export default Loader;