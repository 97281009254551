import React from 'react';

const HomeTop = () => {
    return (
        <div className="homeV3_otwMain">
            <div className="homeV3_otw">
                <div className="homeV3_otw_dot">
                    <div className="innerCircle" />
                    <div className="middleCircle" />
                    <div className="outerCircle" />
                </div>
                <div>Open to work</div>
            </div>
        </div>
    )
};

export default HomeTop;
