import React, { useEffect, useState } from 'react';
import LText from '../../Assets/Ltext.png';
import SText from '../../Assets/Stext.png';
import SelfImage from '../../Assets/selfImage.png';
import idea from '../../Assets/idea.png';
import design from '../../Assets/design.png';
import testing from '../../Assets/testing.png';

const HomeTwo = () => {
    const images = [{
            url: idea,
            text: "Research & Ideation",
            color: "#EF45F0"
        }, 
        {
            url: design,
            text: "Brainstorm & Design",
            color: "#FF7547"
        }, 
        {
            url: testing,
            text: "Prototype & Testing",
            color: "#476FFF"
        }
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000);

        return () => clearInterval(interval);
    },[images?.length]);

    return (
        <div className='flex justify-between mt-28' >
            <div className='mt-8 flex flex-col'>
                <div className='flex flex-col' >
                    <div className="font-satoshi text-custom-xl font-extra-bold" >
                        Transform <span className='italic'> Ideas</span> into <span className='italic'> Experience</span>
                    </div>
                    <div className='flex'>
                        <div className='font-satoshi text-custom-xl font-extra-bold hV3_t2'>
                            people <span className='italic'> Love</span> with
                        </div>
                        <div className="text-slider-container">
                            {images.map((item, index) => (
                                <div
                                    key={index}
                                    className={`text-slider-item ${
                                        index === currentIndex
                                        ? "active"
                                        : index === (currentIndex - 1 + images.length) % images.length
                                        ? "exiting"
                                        : ""
                                    }`}
                                >
                                    <img src={item.url} alt='idea design and testing' />
                                    <div style={{ color: item.color }} >{item.text} </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='mt-8 homeV3_content_subText' >
                    Hi! I’m {" "}
                    <span>
                        N Leina Singha, UI/UX designer {" "}
                    </span>
                    based in India
                </div>
            </div>
            <div className='ml-12 homeV3_ImageSection'>
                <div className='homeV3_ImageAbsoluteTop' >
                    <img src={LText} alt="L Tilted text" />
                </div>
                <div className='hV3_ImageMain'>
                    <img src={SelfImage} alt="Self" />
                </div>
                <div className='homeV3_ImageAbsoluteBottom' >
                    <img src={SText} alt="S Tilted text" />
                </div>
            </div>
        </div>
    )
};

export default HomeTwo;