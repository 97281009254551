import ProjectOne from '../../Assets/projectOne.png';
export const ProjectData = [
    {
        id: "farmersLounge",
        title: "Farmer's Lounge",
        subText: "Order fresh produce through livestream",
        projectImageUrl: '../../Assets/projectOne.png',
        caseStudyUrl: "",
        projectIncludes: ["Web Design", "B2C Platform", "Case Study"]
    },
    {
        id: "farmersLounge",
        title: "Farmer's Lounge 2",
        subText: "Order fresh produce through livestream",
        projectImageUrl: {ProjectOne},
        caseStudyUrl: "",
        projectIncludes: ["Web Design", "B2C Platform", "Case Study"]
    },
    {
        id: "farmersLounge",
        title: "Farmer's Lounge 3",
        subText: "Order fresh produce through livestream",
        projectImageUrl: {ProjectOne},
        caseStudyUrl: "",
        projectIncludes: ["Web Design", "B2C Platform", "Case Study"]
    }
]

export const WorkWord = [
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
    "WORK",
]

export const SectionWord = [
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
    "SECTION",
]

