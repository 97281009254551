import React, { useEffect, useState } from "react";

const LazyImage = ({ src, alt }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = React.useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing after the image is visible
        }
      },
      {
        threshold: 0.1, // Load image when 10% of it is visible
      }
    );

    let reff;

    if (imgRef.current) {
      observer.observe(imgRef.current);
      reff = imgRef.current;
    }

    return () => {
      if (reff) observer.disconnect();
    };
  }, []);

  return (
    <img
      ref={imgRef}
      src={isVisible ? src : ""}
      alt={alt}
      style={{ minHeight: "200px", backgroundColor: "#f0f0f0" }}
    />
  );
};

export default LazyImage;
