import React, { useEffect, useState } from 'react';
import HomeProjects from './HomeProjects.';
import HomeHeader from './HomeHeader';
import Loader from './Loader';
import { PortfolioData } from '../context';

const Home = () => {
  const { projects } = PortfolioData();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);


    useEffect(() => {
        if (projects) {
          setData(projects);
          setTimeout(() => {
            setLoader(false);
          }, 400)
        }
    },[projects]);

  return (
    <div className="homeV3">
        <HomeHeader />
        {data?.length > 0 && <HomeProjects data={data} />}
        {loader && <Loader />}
    </div>
  );
};

export default Home;
