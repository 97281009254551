import React, { useEffect, useRef, useState } from 'react';
import holdingHand from '../../Assets/holdinghand.png';
import FooterImage from '../../Assets/footerImage.png';
import emailImage from '../../Assets/email.png';
import linkedIf from '../../Assets/linkedin.png';
import behance from '../../Assets/behance.png';
import dribble from '../../Assets/dribble.png';
import insta from '../../Assets/insta.png';
import copyright from '../../Assets/copyright.png';
import hand from '../../Assets/hand.png';
import { useLocation } from 'react-router-dom';

const HomeFooter = () => {
    const [imageHeight, setImageHeight] = useState(350);
    const elementRef = useRef(null);
    const location = useLocation();

    const getLocation = () => {
        if (location && location?.pathname && location?.pathname.includes("/project/")) {
            return "project"
        } else return "";
    }

    useEffect(() => {
        const calculateHeight = () => {
            if (elementRef.current) {
                const finalHeight = elementRef.current.offsetHeight;
                setImageHeight(finalHeight);
            }
        };

        setTimeout(() => {
            calculateHeight();
        },[])

        window.addEventListener("scroll", calculateHeight);
    
        return () => {
            window.removeEventListener("scroll", calculateHeight);
        };
    }, []);

    const handleCopy = () => {
        navigator?.clipboard?.writeText("leinasingha08@gmail.com");
    }

    return (
        getLocation() === "" 
        ?
            <div className='homeFooterContainer'  ref={elementRef}>
                <div className='homeFooterBGImage'>
                    <img src={FooterImage} alt="footer" style={{ height: imageHeight }} />
                </div>
                <div className='h3_footerOne'>
                    <div className='h3_fOT font-satoshi' >
                        Let's Get in touch
                        <img src={holdingHand} alt="holdinghand" />
                    </div>
                    <div className='h3_fOE' onClick={handleCopy} >
                        <img src={emailImage} alt="email" />
                        <div className='h3_fOET font-satoshi' >
                            leinasingha08@gmail.com
                        </div>
                        <div className='h3_fOE_RT' >TAP TO COPY</div>
                    </div>
                    <div className='hFBHI' >
                                <img src={hand} alt="hand" />
                    </div>
                    <div className='h3_fOB' >
                        <div className='flex'>
                            <img src={linkedIf} alt='linkedIn' />
                            <img src={behance} alt='behance' />
                            <img src={dribble} alt='dribble' />
                            <img src={insta} alt='insta' />
                        </div>
                        <div className='flex items-center' >
                            <img src={copyright} alt="copyright" />
                            <div className='h3_fOBT ml-2 font-satoshi' >
                                Leina 2024
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div></div>
    )
};

export default HomeFooter;