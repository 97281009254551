import React from 'react';
import StarImage from '../../Assets/star.png';
import LeinaOne from '../../Assets/LeinaOne.png';
import Cat from '../../Assets/cat.png';
import Food from '../../Assets/food.png';
import LeinaTwo from '../../Assets/LeinaTwo.png';
import Samvidhi from '../../Assets/samvidhi.png';
import Applore from '../../Assets/applore.png';

const About = () => {
    return (
        <div>
            <div className='aboutTop' >
                <div className='aboutTop_text font-satoshi' >
                    Hi, I'm
                    <div className='aT_tI' >
                        <div >
                            Leina
                        </div>
                        <div>
                            <img src={LeinaOne} alt="leina small" />
                        </div>
                        <div className='absoluteStar' >
                            <img src={StarImage} alt="star" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='intoSectionContainer'>
                <div className='intoSection'>
                    <div className='iS_I' >
                        <img src={LeinaTwo} alt="leina" />
                    </div>
                    <div className='iS_tI' >
                        <div className='iStI_E' >
                            <div className='iStI_ET font-satoshi' >
                                I'm a UI/UX designer with a passion for creating user-friendly and visually appealing digital experiences. Currenly, I’m freelancing my work blends creativity and strategy to solve real-world problems, from designing intuitive interfaces to enhancing overall user journeys. 
                            </div>
                            <div>
                                <img src={Food} alt="food" />
                            </div>
                        </div>
                        <div className='iStI_E' >
                            <div>
                                <img src={Cat} alt="cat" />
                            </div>
                            <div className='iStI_ET font-satoshi' >
                                I'm always exploring the latest design trends and tools, and I love collaborating with others to bring ideas to life. When I'm not designing, you'll find me enjoying nature or watching movies or chilling with my cats.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='v3_experienceSection' >
                <div className='v3_experienceHeader' >
                    <div className='font-satoshi v3_eH_title' >
                        Previously worked at
                    </div>
                    <div className='v3_eH_backTitle'>
                        Experience
                    </div>
                </div>
                <div className='v3_experienceCompanies'> 
                    <div className='v3_experienceCompany'>
                        <img src={Samvidhi} alt="samvidhi" />
                    </div>
                    <div className='v3_experienceCompany'>
                        <img src={Applore}  alt="applore" />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default About;