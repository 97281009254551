import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { app } from '../../firebase';
import { doc, getDoc ,getFirestore } from "firebase/firestore"; 
import Icon from '../Icons';
import { useNavigate } from "react-router-dom";
import TitleValue from '../TitleValue';
import Banner from '../Banner';
import Section from '../Section';
import Header from '../Header';
import Loader from '../Blocks/Loader';
import { CONTENT_TYPE } from '../constants';

const Project = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [data, setData] = useState();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");
    const [imageLoader, setImageLoader] = useState(true);

    useEffect(() => {
        setLoader(true);
        const getData = async () => {
            const db = getFirestore(app);
            getDoc(doc(db, "projectsPortfolio", id))
            .then(result => {
                setData({ ...result.data() })
                setLoader(false);
            })
            .catch(error => {
                setError(error)
                setLoader(false);
            })
        }
        getData();
    }, [id])

    const handleLoader = () => {
        setImageLoader(false);
    } 

    const handleScrollUp = () => {
        window.scrollTo({ top: 0, behavior: 'smooth'});
    }


    return (
        loader ?  <Loader />
        :
        (error === "" 
        ? 
            <div>
                <div className='absolute top-16 left-12'>
                    <Icon className='cursor-pointer' iconType="backArrow" width="40px" height="40px" onClick={() => navigate("/main") } />
                </div>
                <div>
                    {data?.values?.map((section) => {
                        if (section.type === CONTENT_TYPE.IMAGE) {
                            return (
                                <div key={section.id} >
                                    {section?.title && <Header title={section?.title} titleColor={section?.titleColor} bar={true} /> }
                                    <img src={section.value} alt="projectImage" onLoad={handleLoader} onError={handleLoader} />
                                    {imageLoader && <Loader />}
                                </div>
                            )
                        } else if (section?.type === CONTENT_TYPE.TITLE_VALUE) {
                            return (
                                <TitleValue item={section} />
                            )
                        } else if (section?.type === CONTENT_TYPE.BANNER) {
                            return (
                                <Banner item={section} />
                            )
                        } else if (section.type === CONTENT_TYPE.SECTION) {
                            return (
                                <Section data={section} />
                            )
                        } else return <></>
                    })}
                </div>
                <div className='scrollUpBox cursor-pointer' onClick={handleScrollUp} >
                    <Icon iconType="scrollUp" height="40px" width="40px" />
                </div>
            </div>
        : <div>{error}</div>
        )
    )
};

export default Project;